import React, { useEffect, useState } from "react";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import {
  deleteWhitelistWallet,
  getWhiteListWallets,
} from "../../request/white-list-wallets";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../store/actions/alert";

const WhiteListWalletsListing = () => {
  const [whiteListWallets, setWhiteListWallets] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const fetchData = async () => {
    try {
      const response = await getWhiteListWallets();
      if (response?.status === 200) {
        setWhiteListWallets(response?.data);
      } else {
        console.log("error", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteWallet = async (whitelistWallet: any) => {
    try {
      if (!window.confirm("Are you sure?")) {
        return;
      }
      const response = await deleteWhitelistWallet(whitelistWallet?.id);
      if (response?.status === 200) {
        dispatch(alertSuccess("Deleted Successfully"));
        fetchData();
      } else {
        dispatch(alertFailure("Error While Deleting Wallet"));
      }
    } catch (e) {
      dispatch(alertFailure("Error While Deleting Wallet"));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const networkName: any = {
    eth: "ETH",
    bsc: "BSC",
    polygon: "MATIC",
    arbitrum: "ARB",
    avalanche: "AVAX",
    solana: "SOL",
    coredao: "CORE",
    zksync: "zkSync",
    base: "BASE",
    linea: "LINEA",
    blast: "BLAST",
    bera: "BERA",
  };

  return (
    <DefaultLayout>
      <>
        <Button
          variant="contained"
          color="primary"
          style={{ marginBottom: "30px" }}
          onClick={() => {
            history.push("add-white-list-wallets");
          }}
        >
          Add Wallet
        </Button>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {/*<TableCell>Id</TableCell>*/}
                <TableCell>Network</TableCell>
                <TableCell>Wallet</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {whiteListWallets?.map((whitelist: any) => (
                <TableRow key={whitelist.id}>
                  <TableCell>{networkName[whitelist.network]}</TableCell>
                  <TableCell>{whitelist.wallet}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        history.push(
                          `edit-white-list-wallets/${whitelist?.id}`
                        );
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        deleteWallet(whitelist);
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </DefaultLayout>
  );
};

export default WhiteListWalletsListing;
