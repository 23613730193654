import React, { useEffect, useState } from "react";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import {
  addWhiteListWallets,
  getWhiteListWalletById,
  updateWhiteListWallet,
} from "../../request/white-list-wallets";
import { useHistory, useParams } from "react-router-dom";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import { useDispatch } from "react-redux";
import { BERA_CHAIN, BLAST_CHAIN, LINEA_CHAIN } from "../../constants";

const WhiteListWalletsForm = () => {
  const [url, setUrl] = useState("");
  const [network, setNetwork] = useState(" ");
  const history = useHistory();
  const [errors, setErrors] = useState<any>({});
  let { id }: any = useParams();
  const dispatch = useDispatch();

  const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (url) {
      setErrors("");
    }
    setUrl(event.target.value);
  };

  const handleNetworkChange = (event: any) => {
    if (network) {
      setErrors("");
    }
    setNetwork(event.target.value);
  };

  const fetchData = async (id: number) => {
    try {
      const response = await getWhiteListWalletById(id);
      if (response?.status === 200) {
        setUrl(response.data.wallet);
        setNetwork(response.data.network);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const errors: any = {};
    if (network === " ") {
      errors.network = "Network is required";
    }
    if (!url) {
      errors.url = "Wallet Address is required";
    }
    let response;
    try {
      if (Object.keys(errors).length === 0) {
        if (id) {
          response = await updateWhiteListWallet({ wallet: url, network }, id);

          if (response.status === 200) {
            dispatch(alertSuccess("whitelist updated successful!"));
          }
        } else {
          response = await addWhiteListWallets({ wallet: url, network });
          if (response.status === 200) {
            dispatch(alertSuccess("Created successful!"));
          }
        }

        if (response?.status === 200) {
          history.push("/dashboard/white-list-wallets");
        } else if (response?.status === 400) {
          dispatch(alertFailure(response.message));
        }
      } else {
        setErrors(errors);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  return (
    <DefaultLayout>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <FormControl variant="outlined" margin="normal" fullWidth>
          <InputLabel id="network-label">Network</InputLabel>
          <Select
            labelId="network-label"
            id="network"
            value={network}
            onChange={handleNetworkChange}
            label="Network"
            error={!!errors.network}
          >
            <MenuItem value=" " disabled>
              Select a Network
            </MenuItem>
            <MenuItem value="bsc">BSC</MenuItem>
            <MenuItem value="eth">ETH</MenuItem>
            <MenuItem value="avalanche">AVAX</MenuItem>
            <MenuItem value="arbitrum">ARB</MenuItem>
            <MenuItem value="polygon">MATIC</MenuItem>
            <MenuItem value="coredao">Core Dao</MenuItem>
            <MenuItem value="solana">Solana</MenuItem>
            <MenuItem value="zksync">zkSync</MenuItem>
            <MenuItem value="base">BASE</MenuItem>
            {LINEA_CHAIN && <MenuItem value="linea">LINEA</MenuItem>}
            {BLAST_CHAIN && <MenuItem value="blast">BLAST</MenuItem>}
            {BERA_CHAIN && <MenuItem value="bera">BERA</MenuItem>}
          </Select>
          {errors.network && (
            <div style={{ color: "red" }}>{errors.network}</div>
          )}
        </FormControl>
        <div>
          <TextField
            label="Wallet Address"
            type="text"
            id="url"
            value={url}
            onChange={handleUrlChange}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.url}
          />
          {errors.url && <div style={{ color: "red" }}>{errors.url}</div>}
        </div>
        <Button
          variant="contained"
          color="primary"
          style={{ marginRight: "10px" }}
          onClick={() => {
            history.push("/dashboard/white-list-wallets");
          }}
        >
          Back
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
    </DefaultLayout>
  );
};

export default WhiteListWalletsForm;
